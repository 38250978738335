//Util
import moment from "moment";
import {React, useState, useEffect} from 'react'

//Firebase
import db from 'firebase'
import { collection, query, where, getDocs, onSnapshot, orderBy, limit } from "firebase/firestore";

// Card
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";


//Modal
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper'
import SensorModal from 'views/Modal/SensorModal'

//Style
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);


//Icon
import AccessTime from "@material-ui/icons/AccessTime";
import {BsWater as LeakageIcon}  from 'react-icons/bs';
import { ReactComponent as TrashCanIcon } from "components/Icon/TrashCan.svg";
import { ReactComponent as PaperDispenserIcon } from "components/Icon/PaperDispenser.svg";
import { ReactComponent as PaperRollIcon } from "components/Icon/Paper.svg";
import { ReactComponent as SoapIcon} from "components/Icon/WashHand.svg"
import { ReactComponent as SeatSanitizerIcon } from "components/Icon/DisinfectionA.svg";

export default function LeakageUsage(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [overallStatus, setOverallStatus] = useState("info")
  const [lastUpdate,setLastUpdate] = useState(moment().format('YYYY-MM-DD HH:mm'))
  const [title,setTitle] = useState()
  const [icon,setIcon] = useState()
  const [modal, setModal] = useState()
  const StatusRef = collection(db, props.DBname)
  const dataPoint = 1
  const updateFeq = 300000

  useEffect(() => {
    switch(props.Sensor) {
      case "leakage": {
        setTitle("Water Leakage")
        setIcon(<LeakageIcon/>)
        break
      }
      case "trash": {
        setTitle("Trash")
        setIcon(<TrashCanIcon/>)
        break
      }
      case "paperDispenser": {
        setTitle("Paper Dispenser")
        setIcon(<PaperDispenserIcon/>)
        break
      }
      case "paperRoll": {
        setTitle("Paper Roll")
        setIcon(<PaperRollIcon/>)
        break
      }
      case "soapDispenser": {
        setTitle("Soap Dispenser")
        setIcon(<SoapIcon/>)
        break
      }
      case "seatSanitizer": {
        setTitle("Seat Sanitizer")
        setIcon(<SeatSanitizerIcon/>)
        break
      }
    }
    fetchStatus();
    const interval = setInterval(() => fetchStatus(), updateFeq);
    return () => {clearInterval(interval)};
  }, [props])

 
  const fetchStatus = async () =>  {
    var tempStatus = [];
    var tempUpdateTime = [];
    const q =  query(StatusRef)
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.data().floor == props.Floor) {
        tempStatus.push(doc.data().status)
        tempUpdateTime.push(doc.data().hkdatetime)
        tempUpdateTime.sort().reverse()
       
       }
      if (tempStatus.includes("danger") ||tempStatus.includes("login")  ) {
        setOverallStatus("danger");
      }else {
        if (tempStatus.includes("warning")){
          setOverallStatus("warning");
        }else {
          setOverallStatus("success");
        }
      }
      })
      setLastUpdate(moment(tempUpdateTime[0]).format('YYYY-MM-DD HH:mm'))
  }

  return(
  <div>    
    <Card>
      <CardHeader color={overallStatus} stats icon>
        <a href="#" style={{ color: "white" }} onClick={() => handleOpen()}>
          <CardIcon color={overallStatus}>
              {icon}
          </CardIcon>
        </a>
        <p className={classes.cardCategory}>{title}</p>
        <h3 className={classes.cardTitle}>{(overallStatus=="success")?("Normal"):("Attention")}</h3>
      </CardHeader>
      <CardFooter stats>
        <div className={classes.stats}>
          <AccessTime /> Last Update {lastUpdate}
        </div>
      </CardFooter>
    </Card>

     <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          
        }}
    >
     <Paper
      style={{
        width: "95%",
        height: "100%",
        backgroundColor: "#767676",
        display: "flex",
        alignSelf: "center",
        alignItems: "flex-start",
        justifyContent: "center",
        overflow: "auto"
      }}
    >
        < SensorModal Title={title} DBname={props.DBname} Floor={props.Floor} ModalHandleClose={handleClose} />
    </Paper>

     </Modal>
  </div>
  )
}
