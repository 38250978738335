import {React, useEffect, useLayoutEffect, useState} from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
//import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Tooltip from "@material-ui/core/Tooltip";
//import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
//import Store from "@material-ui/icons/Store";
//import WavesIcon from '@material-ui/icons/Waves';


// import InfoOutline from "@material-ui/icons/InfoOutline";
//import Warning from "@material-ui/icons/Warning";
//import DateRange from "@material-ui/icons/DateRange";
//import LocalOffer from "@material-ui/icons/LocalOffer";
//import Update from "@material-ui/icons/Update";
//import ArrowUpward from "@material-ui/icons/ArrowUpward";
//import AccessTime from "@material-ui/icons/AccessTime";
//import Refresh from "@material-ui/icons/Refresh";
//import Edit from "@material-ui/icons/Edit";
//import Place from "@material-ui/icons/Place";
//import ArtTrack from "@material-ui/icons/ArtTrack";
//import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
//import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Divider from '@material-ui/core/Divider';
import Paginations from "views/Components/Pagination"

//import CustomTabs from "components/CustomTabs/CustomTabs.js";

//Custom Icon
import { ReactComponent as StairIcon } from "components/Icon/Stair.svg";
import {ReactComponent as MaleToiletIcon} from "components/Icon/MaleToilet.svg"
//import { ReactComponent as SoapIcon } from "components/Icon/WashHand.svg";
import BusinessIcon from '@material-ui/icons/Business';

//IAQ Device List
import deviceIAQList from 'DeviceList/deviceIAQList'

//QuickViews
//import ToiletUsage from "views/QuickView/ToiletUsage";
import MaleToiletUsage from "views/QuickView/MaleToiletUsage"
import FemaleToiletUsage from "views/QuickView/FemaleToiletUsage"
import AccessToiletUsage from "views/QuickView/AccessToiletUsage"
import QuickView from 'views/QuickView/QuickView'
import IAQChartView from 'views/QuickView/IAQChartView'


import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";


const useStyles = makeStyles(styles);
const useStylesButton = makeStyles(buttonsStyle)

export default function Dashboard() {
  const classes = useStyles();
  const classesBtn = useStylesButton()
  const [currentFloor,setCurrentFloor] = useState("3")
  const [currentToiletType, setCurrentToiletType] = useState("female")
  const [currentORG,setCurrentORG] = useState("ym2")
  const [currentCublicleID,setCurrenCublicleID] = useState("1")
 //console.log("dashboard: Type: ", currentToiletType, " Floor:", currentFloor)
  

  const handleClick = (floor) =>{
   setCurrentFloor(floor)
    //alert("you've clicked floorview "+ floor)
  }
  const handleType = ((type =>{
    setCurrentToiletType(type)
  }))

  const handleCublicleID= ((ID =>{
    setCurrenCublicleID(ID)
  }))

  return (
    <div>
      <Divider />

      <GridContainer  >
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info" >
                <BusinessIcon/>
              </CardIcon>
              <p className={classes.cardCategory}>Floor</p>
              <h3 className={classes.cardTitle} style={{fontWeight:"bold"}}> {currentFloor}/F
              <small> </small> <small> </small>
              </h3>
            </CardHeader>
            <CardFooter stats>
            <Paginations
              pages={[
                { text: 1, active: (currentFloor == "1")?(true):(false)},
                { text: 2, active: (currentFloor == "2")?(true):(false)},
                { text: 3, active: (currentFloor == "3")?(true):(false)},
              ]}
              color="info"
              onClick={handleClick}
            />
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer >

      <Divider />
      
      <GridContainer >
     
 
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <FemaleToiletUsage ORG={currentORG} Floor={currentFloor}/>
        </GridItem>

        <GridItem xs={12} sm={6} md={6} lg={3}>
          <MaleToiletUsage ORG={currentORG} Floor={currentFloor}/>
        </GridItem>
        {/*
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <AccessToiletUsage Floor={currentFloor}/>
        </GridItem>
        */}

      </GridContainer>

      {currentFloor=="3"?
         <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <QuickView Sensor = "paperRoll" DBname={currentORG+"_PaperRoll_Status"} Floor={currentFloor} />
          </GridItem>

          <GridItem xs={12}sm={6} md={6} lg={3}>  
            <QuickView Sensor = "seatSanitizer" DBname={currentORG+"_SeatSanitizer_Status"} Floor={currentFloor} /> 
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={3}>
            <QuickView Sensor = "trash" DBname={currentORG+"_TrashLevel_Status"} Floor={currentFloor} />
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={3}>
            <QuickView Sensor = "leakage" DBname={currentORG+"_FloodSensor_Status"} Floor={currentFloor} />
          </GridItem>
        </GridContainer>
        :null}
      
      <Divider/>


{currentFloor=="3"?
    <GridContainer>
      <GridItem>
      {(currentToiletType == "female")?(<Button round color="info">Female</Button>):(<Button simple color="info" onClick={()=>handleType("female")}>Female</Button>)}
      {(currentToiletType == "male")?(<Button round color="info">Male</Button>):(<Button simple color="info" onClick={()=>handleType("male")} >Male</Button>)}
    </GridItem> 
     </GridContainer>
 :null}
 {currentFloor=="3"?
     <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <IAQChartView ChartName = "IAQ - CO2" TenantID = {currentORG} Floor={currentFloor} ToiletType={currentToiletType} SensorMetric = "co2" />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <IAQChartView ChartName = "IAQ - TVOC" TenantID = {currentORG} Floor={currentFloor} ToiletType={currentToiletType} SensorMetric = "tvoc" />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <IAQChartView ChartName = "IAQ - H2S" TenantID = {currentORG} Floor={currentFloor} ToiletType={currentToiletType} SensorMetric = "h2s" />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <IAQChartView ChartName = "IAQ - NH3" TenantID = {currentORG} Floor={currentFloor} ToiletType={currentToiletType} SensorMetric = "nh3" />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <IAQChartView ChartName = "IAQ - HCHO" TenantID = {currentORG} Floor={currentFloor} ToiletType={currentToiletType} SensorMetric = "hcho" />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <IAQChartView ChartName = "IAQ - PM2" TenantID = {currentORG} Floor={currentFloor} ToiletType={currentToiletType} SensorMetric = "pm2" />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <IAQChartView ChartName = "IAQ - Humidity" TenantID = {currentORG} Floor={currentFloor} ToiletType={currentToiletType} SensorMetric = "humidity" />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <IAQChartView ChartName = "IAQ - Temperature" TenantID = {currentORG} Floor={currentFloor} ToiletType={currentToiletType} SensorMetric = "temperature" />
          </GridItem>

        </GridContainer>
 :null}
        </div>
  );
}
  {/*
        <GridItem xs={12} sm={6} md={6} lg={3}>
         <QuickView Sensor = "soapDispenser" DBname={currentORG+"_SoapDispenser_Status"} Floor={currentFloor} />
        </GridItem>
        
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <QuickView Sensor = "paperDispenser" DBname={currentORG+"_PaperDispenser_Status"} Floor={currentFloor} />
        </GridItem>
*/}