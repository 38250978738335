/*eslint-disable*/
import React, { useEffect, useState } from "react";
import moment from 'moment'

//Firebase
import db from 'firebase' 
import { collection, query, where, getDoc, getDocs, onSnapshot } from "firebase/firestore";

// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import AccessTime from "@material-ui/icons/AccessTime";

// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

//IAQ Device List
//import deviceIAQList from 'DeviceList/deviceIAQList'
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);


export default function Charts(props) {
  //console.log("Domain",props.TenantID,"Floor", props.Floor,"Toilet Type", props.ToiletType,"Sensor Metric",props.SensorMetric)
  const IAQStatusRef = collection(db, props.TenantID+"_IAQSensor_Status");  
  const dataPoint = 13
  const updateFeq = 300000
  const classes = useStyles();
  const [overallStatus, setOverallStatus] = useState("info")
  const [lastUpdateTime,setLastUpdateTime] = useState()

  const [chartOptions, setChartOptions] = useState({
    high: 1000,
    low: 0,
    fullWidth: true,
  });  
  const [chartData, setChartData] = useState({
    labels: [1,2,3],
    series: [[1,1],[2,2],[3,3]]
})

useEffect(() => {
    //reset Chart
    setOverallStatus("info")
    setChartData({
        high: 100,
        low: 0,
        fullWidth: true,
      })
    setChartData({
        labels: [0],
        series: [[0,0]]
    })

    //Initial Fetch
    fetchChartData() 

    //Routin Fetch Data
    const interval = setInterval(() => fetchChartData(), 60000);
    return () => {clearInterval(interval)};
       
},[props])

const fetchChartData = async () => {
    const IAQ =  query(IAQStatusRef)
    const querySnapshotIAQ = await getDocs(IAQ);
    
    querySnapshotIAQ.forEach((doc) => {
        if (doc.data().floor == props.Floor && doc.data().type == props.ToiletType ){
           //console.log("data" , doc.data().co2)
           //Set Overall Status
           setOverallStatus(doc.data()[props.SensorMetric+"Status"])

           //Set Last Update 
           setLastUpdateTime(doc.data().hkdatetime)
           
           //Set Chart Option
           setChartOptions({
            high:Math.max(...doc.data()[props.SensorMetric])*1.05,
            low: Math.min(...doc.data()[props.SensorMetric])*0.95,
            fullWidth: true,
            lineSmooth: Chartist.Interpolation.cardinal({
              tension: 10,
            }),
            showPoint: false,
            chartPadding :{
              top:15,
              right: 10,
              bottom:0,
              left: 0
            } ,
        
        })
           //Set Chart Data
           setChartData({
            labels: doc.data().timeaxis.reverse(),
            series: [doc.data()[props.SensorMetric].reverse()]
           })
        }
    })
}

return (
    <div>
   
          <Card chart>
            <CardHeader color={overallStatus}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={chartData}
                type="Line"
                options= {chartOptions}

              />
            </CardHeader>
            <CardBody>
             <h4 className={classes.cardTitle}>{(overallStatus!="info")?(props.ChartName):(props.ChartName+" Not Availible")}</h4>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Last Update {lastUpdateTime} 
              </div>
            </CardFooter>
          </Card>
      
    </div>
  )
}

/*
//Add the following to src/asset/scss/plugins/_plugin-react-chartist.scss
.ct-chart-white-colors .ct-label.ct-horizontal.ct-end {
  position: relative;
  justify-content: flex-end;
  text-align: right;
  transform-origin: 90% 0;
  transform: translate(-100%) rotate(-45deg);
  white-space:nowrap;
  
}
*/

