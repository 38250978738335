module.exports = [
    {
      mqtt: "v1/shio/shioiaq2/d-cw/cgo00101-f/0001",
      dbname: "CGO_1F_IAQ_0001",
      floor: "1",
      type: "female",
      cubicleId: "1",
    },
    {
      mqtt: "v1/shio/shioiaq2/d-cw/cgo00101-f/0002",
      dbname: "CGO_1F_IAQ_0002",
      floor: "1",
      type: "female",
      cubicleId: "2",
    },
    {
      mqtt: "v1/shio/shioiaq2/d-cw/cgo00101-m/0001",
      dbname: "CGO_1F_IAQ_0003",
      floor: "1",
      type: "male",
      cubicleId: "1",
    },
    {
      mqtt: "v1/shio/shioiaq2/d-cw/cgo00101-m/0002",
      dbname: "CGO_1F_IAQ_0004",
      floor: "1",
      type: "male",
      cubicleId: "2",
    },
    
  ];
  