//Util
import moment from "moment";
import {React, useState, useEffect} from 'react'

//Firebase
import db from 'firebase'
import { collection, query, where, getDocs, onSnapshot } from "firebase/firestore";
// Card
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

//Style
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);
//Icon
import { ReactComponent as SmartToiletIcon } from "components/Icon/MF.svg";
import { ReactComponent as FemaleToiletIcon } from "components/Icon/FemaleToilet.svg";
import AccessTime from "@material-ui/icons/AccessTime";

//Modal
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { Rowing } from "@material-ui/icons";
import Paper from '@material-ui/core/Paper'
import FemaleToiletModal from "views/Modal/FemaleToiletModal";


export default function FemaleToiletUsage(props) {

  const CubicleStatusRef = collection(db, props.ORG+"_Cubicle_Status");
  const [overallStatus, setOverallStatus] = useState("info")
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classes = useStyles();
  const [dailyUsage,setDailyUsage] = useState("0");
  const [lastUpdate,setLastUpdate] = useState(moment().format('YYYY-MM-DD HH:mm'))

  
  useEffect(() => {
    fetchStatus()
    const interval = setInterval(() =>   fetchStatus(), 60000);
    return () => {clearInterval(interval)};
  }, [props])


  const fetchStatus = async () => {
    var tempStatus = [] 
    var  tempUpdateTime = [];
    var tempUsage=0
    const cubicle =  query(CubicleStatusRef)
    const querySnapshotCubicle = await getDocs(cubicle);
      querySnapshotCubicle.forEach((doc) => {
          if (doc.data().floor == props.Floor && doc.data().type == "female") {
            tempStatus.push(doc.data().status)
            if(doc.data().status != "login"){tempUpdateTime.push(doc.data().hkdatetime)}
            tempUpdateTime.sort().reverse()
            tempUsage = tempUsage + doc.data().occupancy
           }
           
         
      })
      setLastUpdate(moment(tempUpdateTime[0]).format('YYYY-MM-DD HH:mm'))
      setDailyUsage(tempUsage)
      if (tempStatus.includes("login")  ) {
        setOverallStatus("danger");
      }else {
          setOverallStatus("success");
        }
  }

  return(
    <div>
          <Card>
            
            <CardHeader color="info" stats icon>
              <a href="#" style={{ color: "white" }} onClick={() => handleOpen()}>
                <CardIcon color={overallStatus} >
                  <FemaleToiletIcon/>
                </CardIcon>
              </a>
    
              <p className={classes.cardCategory}>Daily Usage</p>
              
              <h3 className={classes.cardTitle}>
              {dailyUsage}
              </h3>
                
            </CardHeader>

            <CardFooter stats>
              <div className={classes.stats}>
              <AccessTime /> Last Update {lastUpdate}
              </div>
            </CardFooter>
          </Card>
           <Modal
           open={open}
           onClose={handleClose}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
           style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
         >
           <Paper
            style={{
              width: "95%",
              height: "100%",
              backgroundColor: "#767676",
              display: "flex",
              alignSelf: "center",
              alignItems: "flex-start",
              justifyContent: "center",
              overflow: 'auto'
            }}
          >
         <FemaleToiletModal ORG={props.ORG} Floor={props.Floor} ModalHandleClose={handleClose}/> 
          </Paper>

           </Modal>
    </div>
    
  )
}